$btn-focus-box-shadow: none !important;
$theme-colors: (
    "primary": #0F61AB,
    "dark": #444444,
    "light": #D1D1D1,
    "secondary": #333333,
    "info": #00B0F0
);

:root {
    --animate-duration: 800ms;
}

@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@300;400;600&display=swap');
@import "~bootstrap/scss/bootstrap";

* {
    transition-duration: 300ms;
}

textarea:focus,
textarea.form-control:focus,
input.form-control:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="email"]:focus,
input[type="number"]:focus,
[type="text"].form-control:focus,
[type="password"].form-control:focus,
[type="email"].form-control:focus,
[type="tel"].form-control:focus,
[contenteditable].form-control:focus {
    box-shadow: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px $light inset !important;
}

input:-webkit-autofill {
    -webkit-text-fill-color: $dark !important;
}

::placeholder {
    color: rgba($color: #000000, $alpha: 0.2) !important;
    opacity: 1;
}

:-ms-input-placeholder {
    color: rgba($color: #000000, $alpha: 0.2) !important;
}

::-ms-input-placeholder {
    color: rgba($color: #000000, $alpha: 0.2) !important;
}

html,
body {
    font-family: "Outfit", sans-serif;
    color: $dark;
    background-color: white;
}

textarea:focus,
textarea.form-control:focus,
input.form-control:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="email"]:focus,
input[type="number"]:focus,
[type="text"].form-control:focus,
[type="password"].form-control:focus,
[type="email"].form-control:focus,
[type="tel"].form-control:focus,
[contenteditable].form-control:focus {
    box-shadow: none;
}

ol.breadcrumb {
    background: none !important;
    padding: 0 !important;
}

.no-decor {
    text-decoration: none !important;
}

#home-bg {
    background-image: url("./img/home-bg.png");
    background-attachment: fixed;
    background-size: cover;
}

.smaller {
    font-size: 70%;
}

.nav-tabs {
    overflow-x: auto;
    overflow-y: hidden;
    flex-wrap: nowrap;
}

.nav-tabs .nav-link {
    white-space: nowrap;
}

.text-nav-unactive {
    color: #999999 !important;
}

.border-3 {
    border-width: 3px !important;
}

.nav-tabs::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.nav-tabs {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

label {
    color: #999999;
}

.hidden {
    opacity: 0;
}

.animateBox {
    transition-duration: 700ms;
}

:root {
    --animate-delay: 0.5s;
}

.animate__fastest {
    transition-duration: 300ms;
}

.widen {
    letter-spacing: 2.5px;
}